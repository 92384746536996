
.v-btn:not(.v-btn--fab, .v-btn--icon).v-size--large.search-button {
    height: 40px !important;
}
@media (min-width: 768px) {
    .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--large.search-button {
        height: 48px !important;
        width: 100px;
    }
}
